<template>
    <div>
        <div class="title">
            <div class="top" @click="fanTitle">活动设置</div>
            <i class="el-icon-arrow-right"></i>
            <div class="bnt">活动订单</div>
        </div>
        <div class="from">
            <el-form :inline="true" :model="formInline" class="demo-form-inline">
                <el-form-item label="品牌" v-if="user_id === 0">
                <el-select v-model="formInline.ping" placeholder="请选择" @change="pingClick" :disabled="showDe">
                    <el-option v-for="(item,index) in pinglist" :key="index" :label="item.name" :value="item.id"></el-option>
                </el-select>
                </el-form-item>
                <el-form-item label="门店">
                <el-select v-model="formInline.company_id" placeholder="请选择" @change="menClick" :disabled="showDe">
                    <el-option v-for="(item,index) in menData" :key="index" :label="item.name" :value="item.id"></el-option>
                </el-select>
                </el-form-item>
                <el-form-item label="活动名称"> 
                <el-select v-model="formInline.zhuang" placeholder="请选择" @change="zhuangClick" :disabled="showDe">
                    <el-option v-for="(item,index) in nameData" :key="index" :label="item.store_activity_name" :value="item.id"></el-option>
                </el-select>
                </el-form-item>
            </el-form>
        </div>
        <div class="table-cont">
            <el-table
                :max-height="heightmAC"
                :header-cell-style="headCalss"
                :data="listData"
                border
                style="width: 100%">
                <el-table-column
                    prop="store_name" 
                    align="center"
                    label="门店">
                </el-table-column>
                <el-table-column
                    prop="store_activity_name" 
                    align="center"
                    label="活动名称">
                </el-table-column>
                <el-table-column
                    prop="text"
                    align="center"
                    label="领取商品">
                </el-table-column>
                <el-table-column
                    prop="payments"
                    align="center"
                    label="支付金额">
                </el-table-column>
                <el-table-column
                    align="center"
                    prop="phone_num"
                    label="会员手机号">
                </el-table-column>
            </el-table>
        </div>
    </div>
</template>

<script>
    import { brandList, storeList,getOrderList,fagetActivityList} from '../../api/index'
    export default {
        data(){
            return{
                  user_id:null,
                // 查询
                ping:'',
                formInline:{
                    ping:'',
                    company_id:'',
                    zhuang:''
                }, 
                pinglist:[],
                menData:[],
                //表格
                listData: [{}],
                heightmAC:window.innerHeight - 220,
                lirOW:null,
                nameData:[],
                showDe:false
            }
        },
        created(){
            this.lirOW =this.$route.query
            this.user_id = JSON.parse(localStorage.getItem('user_id'))
            this.showDe =  JSON.parse(this.lirOW.show)
            if(this.showDe){
                this.formInline.ping = JSON.parse(this.lirOW.brand_id)
                this.formInline.company_id = JSON.parse(this.lirOW.store_id)
                this.formInline.zhuang = JSON.parse(this.lirOW.id)
            }
           
           
            //判断是否是管理员
            if(this.user_id == 0){
                this._brandList()
            }else{
                this.menData = JSON.parse(localStorage.getItem('store_list'))
                this.formInline.ping = JSON.parse(localStorage.getItem('brand_id'))
                this._queList()
            }
             if(this.showDe){
                this._queListS()
            }
        },
        methods:{
            fanTitle(){
               this.$router.push('/stamps') 
            },
            headCalss() {
                return 'background:#f5f7fa'
            },
            //获取品牌
            _brandList(){
                brandList().then(Er =>{
                    if(Er.data && Er.data.length>0){
                        this.pinglist = Er.data
                        if(this.showDe){
                            this._storeList( this.formInline.ping)
                        }else{
                            this.formInline.ping =  Er.data[0].id
                            this._storeList( Er.data[0].id)
                        }
                       
                    }
                })
            },
            //获取门店
            _storeList(row){
                storeList({
                    brand_id:row
                }).then(Er =>{
                    if(Er.return_code == 200){
                        if(Er.data && Er.data.length>0){
                            this.menData  = Er.data
                            if(!this.showDe){
                                this.formInline.company_id = Er.data[0].id
                            }
                        }else{
                            this.menData  = []
                             this.formInline.company_id = ''
                        }
                        if(!this.showDe){
                            this._queListS()
                        }
                      
                    }
                })
            },
            // 获取活动名称
            _queListS(){
                fagetActivityList({
                    brand_id:this.formInline.ping,
                    activity_id:6,
                    store_id:this.formInline.company_id,
                    status:1
                }).then(er =>{
                    if(er.return_code == 200){
                        console.log(er.data)
                        this.nameData = er.data
                        if( er.data &&  er.data.length >0){
                            if(!this.showDe){
                                this.formInline.zhuang = er.data[0].id
                            }
                        }else{
                            this.formInline.zhuang = ''
                        }
                          this._queList()
                        
                    }
                })
            },
             //选择品牌
            pingClick(val){
                console.log(val)
                 this.formInline.ping  = val
                this._storeList(val)
            },
            //选择门店
            menClick(val){
                this.formInline.company_id = val
                this._queList()
                if(!this.showDe){
                    this._queListS()
                }
            },
            zhuangClick(val){
                   this.formInline.zhuang = val
                   this._queList()
            },
            _queList(){
                console.log('列表')
                getOrderList({
                   brand_id:this.formInline.ping,
                   store_id: this.formInline.company_id,
                   store_activity_id:this.formInline.zhuang
                }).then(er =>{
                    console.log(er.data.data,'-----------')
                    if(er.data && er.data.length>0){
                        er.data.map(ec =>{
                            let c = ''
                            if(ec.order_info && ec.order_info.length >0){
                                ec.order_info.map((ED,index) =>{
                                    if(ec.order_info.length - 1 ==index ){
                                        c+=ED.card_name
                                    }else{
                                        c+ED.card_name+','
                                    }
                                })
                            }
                            ec.text = c
                        })
                    }
                    this.listData = er.data
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    .title {
        height: 40px;
        display: flex;
        align-items: center;
        width: 100%;
        font-weight: bold;
        border-bottom: 1px solid #e6e6e6;
        > .top {
            &:hover {
                cursor: pointer;
                color: #409EFF;
            }
        }
        > i {
            margin: 0px 5px;
        }
        > .bnt {
            font-weight: 500;
        }
   }
   .from{
        padding-top: 20px;
    }
   
</style>